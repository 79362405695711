import React, { useEffect } from "react";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";

import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import DownloadApp from "components/cta/DownloadApp";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";

import heroScreenshotImageSrc from "images/demo/MainLandingPageHero.png";

/* Hero */
const Row = tw.div`flex`;
const HeroRow = tw(Row)`flex-col lg:flex-row justify-between items-center pt-8 lg:pt-12 pb-16 max-w-screen-2xl mx-auto flex-wrap`;

const Column = tw.div`flex-1`;

const TextColumn = tw(Column)`mx-auto lg:mr-0 max-w-2xl lg:max-w-xl xl:max-w-2xl flex-shrink-0`;
const Heading = tw(HeadingBase)`text-center lg:text-left text-primary-900 leading-snug`;
const Description = tw(DescriptionBase)`mt-4 text-center lg:text-left lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;

const FeatureList = tw.ul`mt-6 leading-loose flex flex-wrap max-w-xl mx-auto lg:mx-0`;
const Feature = tw.li`mt-2 flex items-center flex-shrink-0 w-full sm:w-1/2 justify-center lg:justify-start`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`mx-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-8`;
const ImageContainer = tw.div``;
const Image = tw.img`max-w-full rounded-t sm:rounded`;

export default ({ features = null, heading = "Gestion des points de la Belote ou de la Coinche", description = "" }) => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  useEffect(() => {
    window.gtag("js", new Date());
    window.gtag("config", "");
  }, []);

  features = features || [`Comptage de points`, `Historique de vos parties`, `Classement par ELO`, "Suivi de vos joueurs favoris", "Page de profile", "Statistiques de vos parties"];

  return (
    <Container>
      <Header />
      <Content2Xl>
        <HeroRow>
          {/* <UpdateNotice>
              <UpdateNoticeIcon />
              Last updated on 10th September, 2022 - Added support for React v18 and TailwindCSS v3!
            </UpdateNotice> */}
          <TextColumn>
            <Heading as="h1">{heading}</Heading>
            <Description>{description}</Description>
            <FeatureList>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureIcon />
                  <FeatureText>{feature}</FeatureText>
                </Feature>
              ))}
            </FeatureList>
          </TextColumn>
          <ImageColumn>
            <ImageContainer>
              <Image src={heroScreenshotImageSrc} />
            </ImageContainer>
          </ImageColumn>
        </HeroRow>
      </Content2Xl>
      <DownloadApp />

      <Footer />
    </Container>
  );
};
