import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import { sendMessage } from "core/services/contact-service";
import { Container } from "components/misc/Layouts";

const handleSubmit = async (data) => {
  await sendMessage(data);
};

export default () => {
  return (
    <Container>
      <Header />
      <ContactUsForm onSubmit={handleSubmit} />

      <Footer />
    </Container>
  );
};
