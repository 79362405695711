import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBPcPK1D-B4_4K7F3K28stTV-idZsyX6h4",
  authDomain: "atout-34216.firebaseapp.com",
  databaseURL: "https://atout-34216.firebaseio.com",
  projectId: "atout-34216",
  storageBucket: "atout-34216.appspot.com",
  messagingSenderId: "524979352117",
  appId: "1:524979352117:web:49b807614447f8dd0c377c",
  measurementId: "G-LZ95K0DH4X",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
// connectFunctionsEmulator(functions, "localhost", 5001);
// connectFirestoreEmulator(db, "localhost", 8080);
// connectAuthEmulator(auth, "http://localhost:9099");
