import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Politique de confidentialité" }) => {
  return (
    <Container>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Dernière mise à jour : 05 septembre 2023</p>

            <p>
              Cette politique de confidentialité décrit nos politiques et procédures relatives à la collecte, à l'utilisation et à la divulgation de vos informations. lorsque vous utilisez le Service
              et vous informe de vos droits en matière de confidentialité et de la manière dont la loi vous protège.
            </p>

            <p>
              Nous utilisons vos données personnelles pour fournir et améliorer le service. En utilisant le Service, vous acceptez les collecte et utilisation d’informations conformément à la présente
              politique de confidentialité.
            </p>

            <h1>Interprétation et définitions</h1>
            <h2>Interprétation</h2>
            <p>Les mots dont la lettre initiale est en majuscule ont des significations définies dans les conditions suivantes.</p>
            <p>Les définitions suivantes auront la même signification, qu'elles apparaissent au singulier ou au singulier. pluriel.</p>

            <h2>Définitions</h2>
            <p>Aux fins de cette politique de confidentialité :</p>
            <ul>
              <li>
                <p>
                  <strong>Vous</strong> désigne la personne accédant ou utilisant le Service, ou la société, ou autre entité juridique au nom de laquelle cette personne accède ou utilise le Service,
                  le cas échéant.
                </p>
              </li>
              <li>
                <p>
                  <strong>Entreprise</strong> (appelé « la Société », « Nous », « Notre » ou « Notre » dans le présent Accord) fait référence à Atout.
                </p>
              </li>
              <li>
                <strong>Affilier</strong>désigne une entité qui contrôle, est contrôlée par ou est sous contrôle commun avec une partie, où « contrôle » désigne la propriété de 50 % ou plus des
                actions, des participations ou autres titres donnant droit au vote pour l'élection des administrateurs ou de toute autre autorité de gestion.
              </li>
              <li>
                <strong>Compte</strong> désigne un compte unique créé pour vous permettre d'accéder à notre service ou à des parties de notre Service.
              </li>
              <li>
                <strong>Site web</strong> fait référence à Atout, accessible depuis https://atout.app
              </li>{" "}
              <li>
                <strong>Application</strong> fait référence à Atout, accessible depuis le Play Store ou Apple Store ou https://atout.app.
              </li>{" "}
              <li>
                <strong>Service</strong> fait référence à l'Application ou au Site web.
              </li>
              <li>
                <strong>Pays</strong> fait référence à la France.
              </li>
              <li>
                <p>
                  <strong>Fournisseur de services</strong> désigne toute personne physique ou morale qui traite les données pour le compte de la compagnie. Il fait référence aux sociétés tierces ou
                  aux personnes physiques employées par la Société pour faciliter le Service, fournir le Service au nom de la Société, exécuter des services liés au Service ou pour aider la Société à
                  analyser la manière dont le Service est utilisé.
                </p>
              </li>
              <li>
                <strong>Service de médias sociaux tiers</strong> désigne tout site Internet ou tout site Internet de réseau social grâce auquel un Utilisateur peut se connecter ou créer un compte pour
                utiliser le Service.
              </li>
              <li>
                <p>
                  <strong>Données personnelles</strong> désigne toute information relative à une personne identifiée ou identifiable. individuel.
                </p>
              </li>
              <li>
                <strong>Cookies</strong> sont de petits fichiers placés sur votre ordinateur, appareil mobile ou tout autre appareil par un site Web, contenant les détails de votre historique de
                navigation sur ce site Web parmi ses nombreux les usages.
              </li>{" "}
              <li>
                <strong>Données d'utilisation</strong> fait référence aux données collectées automatiquement, soit générées par l'utilisation du Service ou de l'infrastructure du Service elle-même
                (par exemple, la durée d'une visite d'une page).
              </li>
            </ul>

            <h1>Collecte et utilisation de vos données personnelles</h1>
            <h2>Types de données collectées</h2>

            <h3>Données personnelles</h3>
            <p>
              Lors de l'utilisation de notre service, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables. qui peuvent être utilisés pour vous contacter ou
              vous identifier. Les informations personnelles identifiables peuvent inclure, mais ne sont pas limité à:
            </p>
            <ul>
              <li>Adresse e-mail, Identifiant</li> <li>Prénom et nom</li> <li>Données d'utilisation</li>
            </ul>

            <h3>Données d'utilisation</h3>
            <p>Les données d'utilisation sont collectées automatiquement lors de l'utilisation du service.</p>
            <p>
              Les données d'utilisation peuvent inclure des informations telles que l'adresse de protocole Internet de votre appareil (par exemple, l'adresse IP), le type de navigateur, la version du
              navigateur, les pages de notre Service que Vous visitez, l'heure et la date de Votre visite, le temps passé sur ces pages, les identifiants uniques de l'appareil et d'autres données de
              diagnostic.
            </p>
            <p>
              Lorsque vous accédez au Service via ou via un appareil mobile, nous pouvons collecter certaines informations. automatiquement, y compris, mais sans s'y limiter, le type d'appareil mobile
              que vous utilisez, votre appareil mobile unique ID, l'adresse IP de votre appareil mobile, votre système d'exploitation mobile, le type d'Internet mobile navigateur que vous utilisez,
              les identifiants uniques de l'appareil et d'autres données de diagnostic.
            </p>
            <p>
              Nous pouvons également collecter des informations que votre navigateur envoie chaque fois que vous visitez notre service ou lorsque vous accédez à le Service par ou via un appareil
              mobile.
            </p>

            <h3>Technologies de suivi et cookies</h3>
            <p>
              Nous utilisons des cookies et des technologies de suivi similaires pour suivre l'activité sur notre service et stocker certaines information. Les technologies de suivi utilisées sont des
              balises, des balises et des scripts pour collecter et suivre les informations. et pour améliorer et analyser notre service.
            </p>
            <p>
              Vous pouvez demander à Votre navigateur de refuser tous les Cookies ou d'indiquer lorsqu'un Cookie est envoyé. Cependant, si vous n'acceptez pas les cookies, vous ne pourrez peut-être
              pas utiliser certaines parties de notre service.
            </p>
            <p>
              Les cookies peuvent être des cookies « persistants » ou « de session ». Les cookies persistants restent sur votre ordinateur personnel ou appareil mobile lorsque vous vous déconnectez,
              tandis que les cookies de session sont supprimés dès que vous fermez votre site Web. navigateur. En savoir plus sur les cookies :{" "}
              <a href="https://www.termsfeed.com/blog/cookies/">Tout sur les cookies</a>.
            </p>
            <p>Nous utilisons des cookies de session et des cookies persistants aux fins énoncées ci-dessous :</p>
            <ul>
              <li>
                <p>
                  <strong>Cookies nécessaires/essentiels</strong>
                </p>
                <p>Type : Cookies de session</p>
                <p>Administré par : Nous</p>
                <p>
                  Finalité : Ces Cookies sont indispensables pour Vous fournir les services disponibles via le Site Internet et pour Vous permettre d'utiliser certaines de ses fonctionnalités. Ils
                  aident à authentifier les utilisateurs et à prévenir l'utilisation frauduleuse de comptes utilisateur. Sans ces Cookies, les services que Vous avez demandés ne pourront pas être
                  fournis, et Nous utilisez ces cookies uniquement pour vous fournir ces services.
                </p>
              </li>
              <li>
                <p>
                  <strong>Politique de cookies / Avis Acceptation Cookies</strong>
                </p>
                <p>Type : Cookies persistants</p>
                <p>Administré par : Nous</p>
                <p>Finalité : Ces Cookies identifient si les utilisateurs ont accepté l'utilisation de cookies sur le Site Internet.</p>
              </li>
              <li>
                <p>
                  <strong>Cookies de fonctionnalité</strong>
                </p>
                <p>Type : Cookies persistants</p>
                <p>Administré par : Nous</p>
                <p>
                  Finalité : Ces cookies nous permettent de mémoriser les choix que vous faites lorsque vous utilisez le site Web, tels que en mémorisant vos informations de connexion ou votre
                  préférence de langue. La finalité de ces Cookies est de Vous fournir avec une expérience plus personnelle et pour vous éviter de devoir saisir à nouveau vos préférences à chaque fois
                  que vous utiliser le site Web.
                </p>
              </li>
            </ul>
            <p>Pour plus d'informations sur les cookies que nous utilisons et vos choix concernant les cookies, veuillez visiter notre Cookies Politique.</p>

            <h2>Utilisation de vos données personnelles</h2>
            <p>La Société peut utiliser les Données Personnelles aux fins suivantes :</p>
            <ul>
              <li>
                <strong>Pour fournir et maintenir notre service</strong>, y compris pour surveiller l'utilisation de notre Service.
              </li>
              <li>
                <strong>Pour gérer votre compte :</strong> pour gérer votre inscription en tant qu'utilisateur du Service. Le Les Données Personnelles que Vous fournissez peuvent Vous donner accès à
                différentes fonctionnalités du Service qui sont à votre disposition en tant qu'utilisateur enregistré.
              </li>
              <li>
                <strong>Pour l'exécution d'un contrat :</strong> l’élaboration, le respect et la mise en œuvre des contrat d'achat des produits, articles ou services que vous avez achetés ou de tout
                autre contrat avec Nous via le Service.
              </li>
              <li>
                <strong>Vous contacter:</strong> Pour vous contacter par e-mail, appels téléphoniques, SMS ou autre équivalent formes de communication électronique, telles que les notifications push
                d'une application mobile concernant les mises à jour ou des communications informatives liées aux fonctionnalités, produits ou services contractés, y compris les mises à jour de
                sécurité, lorsque cela est nécessaire ou raisonnable pour leur mise en œuvre.
              </li>
              <li>
                <strong>Pour vous fournir</strong> des actualités, des offres spéciales et des informations générales sur d'autres produits, services et événements que nous proposons et similaires à
                ceux que vous avez déjà achetés ou demandés à moins que vous ayez choisi de ne pas recevoir de telles informations.
              </li>
              <li>
                <strong>Pour gérer vos demandes :</strong> Pour répondre et gérer vos demandes auprès de nous.
              </li>
            </ul>

            <p>Nous pouvons partager vos informations personnelles dans les situations suivantes :</p>

            <ul>
              <li>
                <strong>Avec les prestataires de services :</strong> Nous pouvons partager vos informations personnelles avec des fournisseurs de services pour surveiller et analyser l’utilisation de
                notre Service, pour vous contacter.
              </li>
              <li>
                <strong>Pour les transmissions d'entreprises :</strong> Nous pouvons partager ou transférer vos informations personnelles dans en relation avec, ou pendant les négociations de, toute
                fusion, vente des actifs de la Société, financement ou acquisition de tout ou partie de notre activité à une autre société.
              </li>
              <li>
                <strong>Avec les affiliés :</strong> Nous pouvons partager vos informations avec nos sociétés affiliées, auquel cas nous exigera de ces affiliés qu’ils respectent cette politique de
                confidentialité. Les sociétés affiliées comprennent notre société mère et toute autre filiale, partenaire de coentreprise ou autre société que nous contrôlons ou qui est sous contrôle
                commun avec Nous.
              </li>
              <li>
                <strong>Avec les partenaires commerciaux :</strong> Nous pouvons partager vos informations avec nos partenaires commerciaux pour Vous proposer certains produits, services ou
                promotions.
              </li>
              <li>
                <strong>Avec d'autres utilisateurs :</strong>lorsque vous partagez des informations personnelles ou interagissez de toute autre manière dans le zones publiques avec d'autres
                utilisateurs, ces informations peuvent être consultées par tous les utilisateurs et peuvent être publiques distribué à l'extérieur. Si vous interagissez avec d'autres utilisateurs ou
                si vous vous inscrivez via un réseau social tiers Service, vos contacts sur le service de médias sociaux tiers peuvent voir votre nom, votre profil, vos photos et description de Votre
                activité. De même, les autres utilisateurs pourront consulter les descriptions de Votre activité, communiquer avec vous et consulter votre profil.
              </li>
            </ul>

            <h2>Conservation de vos données personnelles</h2>
            <p>
              La Société conservera vos données personnelles uniquement aussi longtemps que nécessaire aux fins énoncées dans cette politique de confidentialité. Nous conserverons et utiliserons vos
              données personnelles dans la mesure nécessaire pour nous conformer à nos obligations légales (par exemple, si nous sommes tenus de conserver vos données pour nous conformer aux lois
              applicables), résoudre les litiges et appliquer nos accords et politiques juridiques.
            </p>
            <p>
              La Société conservera également les données d'utilisation à des fins d'analyse interne. Les données d'utilisation sont généralement conservées pour une durée plus courte, sauf lorsque
              ces données sont utilisées pour renforcer la sécurité ou pour améliorer la fonctionnalité de notre service, ou nous sommes légalement tenus de conserver ces données pendant des périodes
              plus longues.
            </p>

            <h2>Transfert de vos données personnelles</h2>
            <p>
              Vos informations, y compris vos données personnelles, sont traitées dans les bureaux opérationnels de la Société et dans tout d'autres lieux où se trouvent les parties impliquées dans le
              traitement. Cela signifie que ces informations peuvent être transférés vers – et conservés sur – des ordinateurs situés en dehors de votre état, province, pays ou autre juridiction
              gouvernementale où les lois sur la protection des données peuvent différer de celles de votre juridiction.
            </p>
            <p>Votre consentement à cette politique de confidentialité suivi de votre soumission de ces informations représente votre accord sur ce transfert.</p>
            <p>
              La Société prendra toutes les mesures raisonnablement nécessaires pour garantir que vos données soient traitées de manière sécurisée et en toute sécurité. conformément à la présente
              Politique de Confidentialité et aucun transfert de Vos Données Personnelles n’aura lieu vers un organisation ou un pays à moins que des contrôles adéquats soient en place, y compris la
              sécurité de vos données et d'autres informations personnelles.
            </p>

            <h2>Divulgation de vos données personnelles</h2>
            <h3>Transactions commerciales</h3>
            <p>
              Si la Société est impliquée dans une fusion, une acquisition ou une vente d'actifs, vos données personnelles peuvent être transférées. Nous vous informerons avant que vos données
              personnelles ne soient transférées et ne soient soumises à une autre politique de confidentialité. Politique.
            </p>
            <h3>Forces de l'ordre</h3>
            <p>
              Dans certaines circonstances, la Société peut être tenue de divulguer vos données personnelles si cela est nécessaire. ainsi en vertu de la loi ou en réponse à des demandes valables des
              autorités publiques (par exemple un tribunal ou un organisme gouvernemental).
            </p>
            <h3>Autres exigences légales</h3>
            <p>La Société peut divulguer vos données personnelles en croyant de bonne foi qu'une telle action est nécessaire pour :</p>
            <ul>
              <li>Se conformer à une obligation légale</li>
              <li>Protéger et défendre les droits ou les biens de la Société</li>
              <li>Prévenir ou enquêter sur d'éventuels actes répréhensibles en relation avec le Service</li>
              <li>Protéger la sécurité personnelle des Utilisateurs du Service ou du public</li>
              <li>Se protéger contre la responsabilité légale</li>
            </ul>

            <h2>Sécurité de vos données personnelles</h2>
            <p>
              La sécurité de vos données personnelles est importante pour nous, mais rappelez-vous qu'aucune méthode de transmission par Internet, ou méthode de stockage électronique, est 100 %
              sécurisé. Bien que nous nous efforcions d'utiliser commercialement moyens acceptables pour protéger vos données personnelles, nous ne pouvons garantir leur sécurité absolue.
            </p>

            <h1>Confidentialité des enfants</h1>
            <p>
              Notre service ne s'adresse pas aux personnes de moins de 13 ans. Nous ne collectons pas sciemment des données personnelles informations identifiables provenant de toute personne de moins
              de 13 ans. Si vous êtes un parent ou un tuteur et que vous êtes sachant que votre enfant nous a fourni des données personnelles, veuillez nous contacter. Si nous prenons conscience que
              nous avons collecté des données personnelles auprès de toute personne de moins de 13 ans sans vérification du consentement parental, nous prendre des mesures pour supprimer ces
              informations de nos serveurs.
            </p>
            <p>
              Si nous devons nous appuyer sur le consentement comme base légale pour le traitement de vos informations et que votre pays l'exige consentement d'un parent, nous pouvons exiger le
              consentement de votre parent avant de collecter et d'utiliser ces informations.
            </p>

            <h1>Liens vers d'autres sites Web</h1>
            <p>
              Notre Service peut contenir des liens vers d'autres sites Web qui ne sont pas exploités par Nous. Si vous cliquez sur un tiers lien, vous serez dirigé vers le site de ce tiers. Nous vous
              conseillons fortement de consulter la politique de confidentialité de chaque site que vous visitez.
            </p>
            <p>Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques de tout sites ou services tiers.</p>

            <h1>Modifications de cette politique de confidentialité</h1>
            <p>
              Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous informerons de tout changement en publiant le nouveau Politique de confidentialité sur cette
              page.
            </p>
            <p>
              Nous vous en informerons par e-mail et/ou par un avis bien visible sur notre service, avant que le changement ne devienne en vigueur et mettez à jour la date de « Dernière mise à jour »
              en haut de cette politique de confidentialité.
            </p>
            <p>
              Il vous est conseillé de consulter périodiquement cette politique de confidentialité pour tout changement. Modifications de cette politique de confidentialité sont efficaces lorsqu’ils
              sont publiés sur cette page.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </Container>
  );
};
